import { TABLET_QUERY_MAX_WIDTH } from "/constants/vp-sizes";
import styled from "@emotion/styled";
export const StyledEmailInput = styled.input`
  min-width: 240px;
  max-width: 320px;
  display: flex;
  flex-grow: 1;
  outline: none;

  &:not(.force-horizontal).mobile-or-tablet {
    min-width: 120px;
    max-width: none;
    width: 100%;
  }
`;
